import React, { Component } from 'react';
import Select from 'react-select';
import firebase from './services/firebase';

const alphaSorter = (a, b) => {
  if (a.value > b.value) {
    return 1;
  }
  if (b.value > a.value) {
    return -1;
  }
  return 0;
};

export default class App extends Component {

	constructor( props ) {

		super( props );

		this.state = {
			leaderboard: null,
			me: null,
			tasting: null,
			selected: null,
			formCountries: null,
			left: null,
			right: null,
			userKey: null,
			loadingQuiz: false,
			showLeaderboard: false,
			showSummary: false
		};

	}

	componentDidMount() {

		document.addEventListener( 'gesturestart', function (e) {
			e.preventDefault();
		} );

		this.setState( { userKey: sessionStorage.getItem( 'userKey' ) } );

		firebase.database().ref( 'users' ).orderByChild( 'points/total' ).on( 'value', ( snapshot ) => {

			if ( snapshot.val() !== null ) {

				var lb = [];
				var m = null;
				var ready = 0;

				snapshot.forEach( ( user ) => {

					var u = user.val();
					u.$key = user.key;
					lb.push( u );

					if ( this.state.userKey === u.$key ) {
						m = u;
					}

					if ( u.ready ) {
						ready++;
					}

				} );

				lb.reverse();

				Object.entries( lb ).forEach( ( [ i, v ] ) => {

					if ( m === v ) {
						m.rank = parseInt( i, 10 ) + 1;
					}

				} );

				this.setState( { 
					leaderboard: lb, 
					me: m 
				} );

				if ( m ) {
					this.loadQuiz();
				}

			}

		} );

	}

	loadQuiz() {

		if ( this.state.loadingQuiz ) {
			return;
		}

		firebase.database().ref( 'tastings' ).orderByChild( 'num' ).on( 'value', ( snapshot ) => {

			if ( snapshot.val() !== null ) {

				const totalTastings = snapshot.numChildren();
				var loopCount = 0;
				var completedTastings = [];
				var lastCompletedTasting = null;
				var activeSet = false;

				snapshot.forEach( ( t ) => {

					var tasting = null;
					loopCount++;

					if ( t.val().status === 'completed' ) {

						tasting = t.val();
						tasting.$key = t.key;
						completedTastings.push( tasting );
						lastCompletedTasting = tasting;

					} else if ( t.val().status === 'active' ) {

						var left = null;
						var right = null;
						tasting = t.val();
						tasting.$key = t.key;

						if ( this.state.me && tasting.left && !tasting.left.answers ) {
							tasting.left.answers = {};
						}

						if ( this.state.me && tasting.left && !tasting.left.answers[this.state.me.$key] ) {
							var leftChoices = {
								country: null,
								region: null,
								grape: null,
								producer: null,
								year: null
							}

							if ( this.state.left && this.state.tasting && this.state.tasting.num === tasting.num ) {
								leftChoices = this.state.left
							}

							tasting.left.answers[this.state.me.$key] = leftChoices;
						}

						if ( this.state.me && tasting.right && !tasting.right.answers ) {
							tasting.right.answers = {};
						}

						if ( this.state.me && tasting.right && !tasting.right.answers[this.state.me.$key] ) {
							var rightChoices = {
								country: null,
								region: null,
								grape: null,
								producer: null,
								year: null
							}

							if ( this.state.right && this.state.tasting && this.state.tasting.num === tasting.num ) {
								rightChoices = this.state.right;
							}

							tasting.right.answers[this.state.me.$key] = rightChoices;
						}


						if ( this.state.me && tasting.left ) {
							left = tasting.left.answers[this.state.me.$key];
						}

						if ( this.state.me && tasting.right ) {
							right = tasting.right.answers[this.state.me.$key];
						}

						activeSet = true;

						delete tasting.left.answers;
						delete tasting.right.answers;

						this.setState( {
							showSummary: false,
							tasting,
							left,
							right,
							showLeaderboard: false
						} );

						return false;

					}

					if ( loopCount === totalTastings && lastCompletedTasting && !activeSet ) {
						this.setState( {
							tasting: lastCompletedTasting,
							showLeaderboard: true
						} );
					}

				} );

				if ( completedTastings.length === totalTastings ) {
					this.setState( {
						showSummary: true,
						tasting: completedTastings,
						showLeaderboard: true
					} );
				} 

			}

		} );

		firebase.database().ref( 'countries' ).once( 'value' ).then( ( snapshot ) => {

			if ( snapshot.val() !== null ) {

				var listCountries = [];
				var listRegions = {};
				var listGrapes = {};
				var listProducers = {};
				var listYears = [];

				snapshot.forEach( ( c ) => {

					var country = c.val();
					listCountries.push( { value: country.name, label: country.name } );

					listRegions[country.name] = country.regions.map( ( region, index ) => {
						return { value: region, label: region };
					} );

					listGrapes[country.name] = country.grapes.map( ( grape, index ) => {
						return { value: grape, label: grape };
					} );

					listProducers[country.name] = country.producers.map( ( producer, index ) => {
						return { value: producer, label: producer };
					} );

				} );

				let thisYear =  new Date().getFullYear();
				for ( var i = thisYear; i > 1992; i-- ) {

					listYears.push( { value: i, label: i } );

				}

				this.setState( { 
					listCountries: listCountries,
					listRegions: listRegions,
					listGrapes: listGrapes,
					listProducers: listProducers,
					listYears: listYears
				} );

			}

		} );

		this.setState( {
			loadingQuiz: true
		} );

	}

	deselectUser() {

		sessionStorage.userKey = null;
		firebase.database().ref( 'users' ).child( this.state.me.$key ).update( { ready: false } );

		this.setState( {
			me: null,
			userRef: null
		} );

	}

	readyUp() {
		
		firebase.database().ref( 'users' ).child( this.state.me.$key ).update( { ready: true } );

	}

	selectUser( user ) {

		sessionStorage.userKey = user.$key;
		var userKey = sessionStorage.getItem( 'userKey' );

		this.setState( {
			me: user,
			userKey: userKey
		} );

		this.loadQuiz();

	}

	selectSide( side ) {

		this.setState( {
			selected: side
		} );

	}

	saveAnswer() {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;
		} 

		this.setState( {
			selected: null
		} );

		if ( ! side.country && ! side.region && ! side.grape && ! side.year ) {
			return;
		}

		var child = this.state.tasting.$key + '/' + this.state.selected + '/answers/' + this.state.me.$key;
		firebase.database().ref( 'tastings' ).child( child ).update( side );

	}

	showLeaderboard() {

		this.setState( {
			showLeaderboard: true
		} );

	}

	hideLeaderboard() {

		this.setState( {
			showLeaderboard: false
		} );

	}

	renderReadyUp() {

		return (
			<div className="ready-up ctr">
			{ ! this.state.me.ready ?
				<button className="btn--gray my1" onClick={() => this.readyUp()}>Er du klar, {this.state.me.name}?</button>
			:
				<div className="ready bg--green p1 fnt--white bold">Du er klar! Venter på de andre deltagere ...</div>
			}
				<button className="btn--link btn--s" onClick={() => this.deselectUser()}>Vælg en anden bruger</button>
			</div>
		);

	}

	renderUserPick() {

		var users = this.state.leaderboard.map( ( user, index ) => {
			return <li className="btn--gray" onClick={() => this.selectUser(user)} key={user.$key}>{ user.name } { user.ready ? <span className="fnt--green bold small">klar!</span> : ''}</li>;
		} );

		return (
			<div className="select-user ctr">
				<p>Vælg dit navn</p>
				<ul>{ users }</ul>
			</div>
		);

	}

	renderSummarySide(key, user, points) {
		return ( 
			<li key={key} className="res--user">
				<span className="name">{ user.name }</span>
				<span className="points">{ points.points }</span>
				<span className={'stat stat--country ' + ( points.hitters.charAt(0) === '1' ? 'stat--good' : 'stat--bad' )}>L</span>
				<span className={'stat stat--region ' + ( points.hitters.charAt(1) === '1' ? 'stat--good' : 'stat--bad' )}>R</span>
				<span className={'stat stat--grape ' + ( points.hitters.charAt(2) === '1' ? 'stat--good' : 'stat--bad' )}>D</span>
				<span className={'stat stat--producer ' + ( points.hitters.charAt(3) === '1' ? 'stat--good' : 'stat--bad' )}>P</span>
				<span className={'stat stat--yaer ' + ( points.hitters.charAt(4) === '1' ? 'stat--good' : 'stat--bad' )}>Å</span>
			</li>
		);
	}

	renderSummary() {

		var players = this.state.leaderboard.map( ( user, index ) => {
			return (
				<li className={'lb--user' + ( this.state.me === user ? ' me' : '')} key={user.$key}>
					<span>{ user.name }</span>
					<span className="bold">{ user.points.total }</span>
				</li>
			);
		} );

		var tastings = this.state.tasting.map( ( tasting, index ) => {

			console.log('### sum state', this.state)

			if ( tasting.left && tasting.left.answers ) {

				var leftResults = Object.entries( tasting.left.answers ).map( ( [ key, result ] ) => {
					var user = null;
					this.state.leaderboard.forEach( (v, i) => {
						if ( v.$key === key ) {
							user = v;
							return;
						}
					} );

					if ( ! user ) {
						return '';
					}

					return this.renderSummarySide(key, user, user.points.tastings[tasting.$key].left);
				} );

			}

			if ( tasting.right && tasting.right.answers ) {

				var rightResults = Object.entries( tasting.right.answers ).map( ( [ key, result ] ) => {
					var user = null;
					this.state.leaderboard.forEach( (v, i) => {
						if ( v.$key === key ) {
							user = v;
							return;
						}
					} );

					if ( ! user ) {
						return '';
					}

					return this.renderSummarySide(key, user, user.points.tastings[tasting.$key].right);
				} );

			}

			return (
				<div key={tasting.num} className="tasting-summary">
					<h2 className="txt--center upr">Smagning { tasting.num }</h2>
				{ tasting.left &&
					<h3 className="txt--center"><span className="fnt--dark-gray upr bold small">Venstre glas:</span> <span>{ tasting.left.bottle }</span></h3>
				}
				{ tasting.left &&
					<ul className="results">
						{ leftResults }
					</ul>
				}
				{ tasting.right &&
					<h3 className="txt--center"><span className="fnt--dark-gray upr bold small">Højre glas:</span> <span>{ tasting.right.bottle }</span></h3>
				}
				{ tasting.right &&
					<ul className="results">
						{ rightResults }
					</ul>
				}
				</div>
			);
		});

		return (
			<div className="summary">
				<p className="txt--center fnt--green bold upr">Spillet er slut!</p>
				<div className="small-lb bg--light-gray">
					<ul>{ players }</ul>
				</div>
				{ tastings }
			</div>
		);

	}

	renderLeaderboard() {

		var players = this.state.leaderboard.map( ( user, index ) => {
			return (
				<li className={'lb--user' + ( this.state.me === user ? ' me' : '')} key={user.$key}>
					<span>{ user.name }</span>
					<span className="bold">{ user.points.total }p</span>
				</li>
			);
		} );

		return (
			<div className="leaderboard fix--full fnt--white" onClick={() => this.hideLeaderboard()}>
				<div className="leaderboard-wrap">
					<ul>{ players }</ul>
				</div>
			</div>
		);

	}

	countryChange = ( selectedOption ) => {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		var newSide = JSON.parse( JSON.stringify( side ) );

		newSide.country = selectedOption.value;
		newSide.region = null;
		newSide.grape = null;

		if ( this.state.selected === 'left' ) {

			this.setState( {
				left: newSide
			} );

		} else {

			this.setState( {
				right: newSide
			} );

		}

	}

	regionChange = ( selectedOption ) => {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		var newSide = JSON.parse( JSON.stringify( side ) );

		newSide.region = selectedOption.value;

		if ( this.state.selected === 'left' ) {

			this.setState( {
				left: newSide
			} );

		} else {

			this.setState( {
				right: newSide
			} );

		}

	}

	grapeChange = ( selectedOption ) => {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		var newSide = JSON.parse( JSON.stringify( side ) );

		newSide.grape = selectedOption.value;

		if ( this.state.selected === 'left' ) {

			this.setState( {
				left: newSide
			} );

		} else {

			this.setState( {
				right: newSide
			} );

		}

	}

	producerChange = ( selectedOption ) => {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		var newSide = JSON.parse( JSON.stringify( side ) );

		newSide.producer = selectedOption.value;

		if ( this.state.selected === 'left' ) {

			this.setState( {
				left: newSide
			} );

		} else {

			this.setState( {
				right: newSide
			} );

		}

	}

	yearChange = ( selectedOption ) => {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		var newSide = JSON.parse( JSON.stringify( side ) );

		newSide.year = selectedOption.value;

		if ( this.state.selected === 'left' ) {

			this.setState( {
				left: newSide
			} );

		} else {

			this.setState( {
				right: newSide
			} );

		}

	}

	renderForm() {

		var side = this.state.right;

		if ( this.state.selected === 'left' ) {
			side = this.state.left;			
		}

		return (
			<div className="form-wrapper">
				<div>
					<label className="muted small bold">Land</label>
					<Select
						placeholder="Vælg"
						name="select-country"
						value={ side.country && { label: side.country } }
						onChange={ this.countryChange }
						options={ this.state.listCountries }
					/>
				</div>
			{ side.country &&
				<div>
					<label className="muted small bold">Region</label>
					<Select
						placeholder="Vælg"
						name="select-region"
						value={ side.region && { label: side.region } }
						onChange={ this.regionChange }
						options={ this.state.listRegions[side.country] }
					/>
				</div>
			}
			{ side.country &&
				<div>
					<label className="muted small bold">Drue</label>
					<Select
						placeholder="Vælg"
						name="select-grape"
						value={ side.grape && { label: side.grape } }
						onChange={ this.grapeChange }
						options={ this.state.listGrapes[side.country] }
					/>
				</div>
			}
			{ side.country &&
				<div>
					<label className="muted small bold">Producent</label>
					<Select
                      placeholder="Vælg"
                      name="select-producer"
					  value={ side.producer && { label: side.producer } }
                      onChange={ this.producerChange }
                      options={ this.state.listProducers[side.country].sort(alphaSorter) }
					/>
				</div>
			}
				<div>
					<label className="muted small bold">Årgang</label>
					<Select
						placeholder="Vælg"
						name="select-year"
					  	value={ side.year && { label: side.year } }
						onChange={ this.yearChange }
						options={ this.state.listYears }
					/>
				</div>
			</div>
		);

	}

	renderTasting() {
		return (
			<div className="tasting">
				<p className="p0 m0 upr muted bold taste-num">Smagning { this.state.tasting.num } / 4</p>
			{ this.state.selected ?
				<div className="tasting-wrap">
					<p className="p0 m0 upr bold taste-glass">{ this.state.selected === 'left' ? 'venstre glas' : 'højre glas' }</p>
					{ this.renderForm() }
					<button className="save-answer bg--green fnt--white upr bold" onClick={() => this.saveAnswer()}>Gem svar</button>
				</div>
			:
				<div className="tasting-wrap ctr">
				{ this.state.left && this.state.tasting.status === 'active' &&
					<button className={'upr bold ' + ( this.state.left.country ? 'btn--green' : 'btn--gray' )} onClick={() => this.selectSide('left')}>Venstre glas <span className="small muted bold">{ this.state.left && this.state.left.country }{ this.state.left.region && ', ' + this.state.left.region }{ this.state.left.grape && ', ' + this.state.left.grape }{ this.state.left.year && ' (' + this.state.left.year + ')' }</span></button>
				}
				{ this.state.right && this.state.tasting.status === 'active' &&
					<button className={'upr bold ' + ( this.state.right.country ? 'btn--green' : 'btn--gray' )} onClick={() => this.selectSide('right')}>Højre glas <span className="small muted bold">{ this.state.right.country && this.state.right.country }{ this.state.right.region && ', ' + this.state.right.region }{ this.state.right.grape && ', ' + this.state.right.grape }{ this.state.right.year && ' (' + this.state.right.year + ')' }</span></button>
				}
				{ this.state.tasting.status === 'completed' &&
					<div>Smagning nr. {this.state.tasting.num} er slut. Vent på næste starter.</div>
				}
					<button className="btn--link bold" onClick={() => this.showLeaderboard()}>Du er nr. { this.state.me.rank } med { this.state.me.points.total } point</button>
				</div>
			}
			</div>
		);

	}

	render() {
		return (

			<div className="app">

			{ ! this.state.me && ! this.state.leaderboard && ! this.state.tasting &&
				<div className="loading-indicator">Loading</div>
			}

			{ this.state.showSummary && this.state.tasting &&
				this.renderSummary()
			}

			{ this.state.leaderboard && this.state.showLeaderboard && !this.state.showSummary &&
				this.renderLeaderboard()
			}

			{ ! this.state.me && this.state.leaderboard && ! this.state.showSummary &&
				this.renderUserPick()
			}

			{ this.state.me && ! this.state.me.playing && ! this.state.tasting && ! this.state.showSummary &&
				this.renderReadyUp()
			}

			{ this.state.tasting && this.state.me && ! this.state.showSummary &&
				this.renderTasting()
			}

			</div>

		);

	}

}
