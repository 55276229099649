import firebase from 'firebase';

const config = {
	apiKey: "AIzaSyBFTdBi0h3kMAuiwCykrKbtKyNAIRFIGCs",
	authDomain: "wine-bering.firebaseapp.com",
	databaseURL: "https://wine-bering.firebaseio.com",
	projectId: "wine-bering",
	storageBucket: "wine-bering.appspot.com",
	messagingSenderId: "209994527658"
};

firebase.initializeApp(config);
export default firebase;
